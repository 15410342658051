
export default {
  name: "MenuConfiguracion",
  data() {
    return {
      menus: [
        {
          titulo: "Configuración",
          icono: "configuracion",
          opciones: [
            { nombre: "Datos generales", ruta: "datosGenerales" },
            {
              nombre: "Usuarios",
              ruta: "usuariosGestor",
              rutasRelacionadas: ["usuariosDetalle"],
            },
            {
              nombre: "Perfiles",
              ruta: "perfilesGestor",
              rutasRelacionadas: ["perfilesDetalle"],
            },
            { nombre: "Embudo de ventas", ruta: "embudosGestor" },
            { nombre: "Categorías artículos", ruta: "articulosCategorias" },
            { nombre: "Marcas de artículos", ruta: "articulosMarcas" },
            { nombre: "Plataformas", ruta: "plataformasGestor" },
            { nombre: "Campañas", ruta: "campaniasGestor" },
            { nombre: "Canales de captación", ruta: "canalesCaptacionGestor" },
            { nombre: "Configuracion SMTP", ruta: "datosSMTP" },
            { nombre: "Industrias", ruta: "industriasGestor" },
          ],
        },
      ],
    };
  },
  methods: {
    validarActiveRuta(r) {
      return (
        this.$route.name === r.ruta ||
        _.filter(r.rutasRelacionadas, (rr) => this.$route.name === rr).length >
          0
      );
    },
  },
};
